<template>
  <div class="news">
    <div class="head-public animate__animated animate__fadeIn">
      <img src="@/assets/img/banner6.png" alt="" />
    </div>
    <div class="cnt-public">
      <div class="title-public">
        <p>Press center</p>
        <h3>
          <span class="line-l"></span>
          <span class="center">新闻中心</span>
          <span class="line-r"></span>
        </h3>
      </div>
      <div class="matter">
        <div class="top-news" @click="skip(topNews)">
          <div class="img-wp">
            <img :src="topNews.cover" alt="" />
          </div>
          <div class="text">
            <h3>{{ topNews.title }}</h3>
            <p class="top-news-cnt" v-html="topNews.content"></p>
          </div>
        </div>
        <div class="wp">
          <div
            :class="(index - 2) % 3 == 0 ? 'end' : ''"
            v-for="(item, index) in list"
            :key="index"
            @click="skip(item)"
          >
            <img :src="item.cover" alt="" />
            <div class="text">
              <h3>{{ item.title }}</h3>
              <p class="content-wp" v-html="item.content"></p>
            </div>
          </div>
        </div>
        <div class="block">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="total"
            :current-page.sync="page"
            :page-size="pageSize"
            @current-change="pageChange"
            prev-text="上一页"
            next-text="下一页"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
    
<script>
import timeGet from "@/utils/timeGet.js";
export default {
  name: "",
  data() {
    return {
      list: [],
      page: 1,
      total: 0,
      pageSize: 6,
      topNews: "",
    };
  },
  methods: {
    gain() {
      this.axios({
        method: "get",
        url: "/wzn/articles/page",
        params: {
          pageNo: this.page,
          pageSize: this.pageSize,
          isPinned: false,
        },
      }).then((res) => {
        console.log("其他新闻", res.data);
        this.list = res.data.data.list.reverse().map((item) => {
          item.createTime = timeGet(item.createTime);
          return item;
        });
        // this.list = res.data.data.list.reverse();
        this.total = res.data.data.total;
      });
    },
    pageChange() {
      this.gain();
    },
    skip(item) {
      this.$router.push({
        path: "/News/info",
        query: {
          title: item.title,
          cnt: item.content,
          imgUrl: item.cover,
          time: item.createTime,
        },
      });
    },
  },
  created() {
    this.axios({
      method: "get",
      url: "/wzn/articles/page",
      params: {
        pageNo: 1,
        pageSize: 100,
        isPinned: true,
      },
    }).then((res) => {
      console.log("置顶新闻", res.data);
      this.topNews = res.data.data.list[0];
      this.topNews.createTime = timeGet(this.topNews.createTime);
    });
    this.gain();
  },
};
</script>
    
<style scoped>
/* .news {
  min-height: 100vh;
} */
.matter {
  padding: 0 10%;
}
.top-news {
  display: flex;
  padding: 20px;
  box-sizing: border-box;
  background: #f5f5f5;
  cursor: pointer;
  margin-bottom: 50px;
}
.top-news .img-wp {
  width: 39%;
  overflow: hidden;
}
.top-news .img-wp img {
  width: 100%;
  transition: all 0.4s;
}
.top-news:hover .img-wp img {
  transform: scale(1.1);
}
.top-news .text {
  margin: 0 5%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 0.4s;
}
.top-news:hover .text {
  opacity: 0.6;
}
.top-news .text h3 {
  font-size: 20px;
  margin-bottom: 20px;
}
.top-news .text p {
  line-height: 26px;
  font-size: 16px;
  color: #666;
}
.top-news .top-news-cnt {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.wp {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-around; */
}
.wp > div {
  width: 30%;
  margin-bottom: 30px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  margin-right: 5%;
}
.wp > .end {
  margin-right: 0;
}

.wp > div > img {
  width: 100%;
  height: 300px;
  transition: all 0.3s;
}

.wp > div:hover img {
  /* box-shadow: 0 13px 52px rgba(172, 190, 215, 0.28); */
  transform: scale(1.1);
}
.wp > div::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  pointer-events: none;
}
.wp > div::after {
  width: 100%;
  height: 70%;
  position: absolute;
  left: 0%;
  bottom: 0%;
  content: "";
  background: linear-gradient(-180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
  opacity: 0;
  transition: all 0.5s;
}
.wp > div:hover::before {
  animation: gradient-rectangle 1s 1;
  animation-delay: 0s;
  animation-timing-function: cubic-bezier(0, 0, 0.18, 0.96);
}
.wp > div:hover::after {
  opacity: 1;
}
@keyframes gradient-rectangle {
  0% {
    transform: scaleY(0);
    transform-origin: bottom;
    background: linear-gradient(
      to top,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.6)
    );
    opacity: 1;
  }
  100% {
    transform: scaleY(1);
    transform-origin: top;
    background: linear-gradient(
      to top,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.6)
    );
    opacity: 0;
  }
}
.wp .text {
  padding: 24px;
  background: #fff;
}
.wp .text > h3 {
  font-size: 18px;
}
.content-wp {
  height: 25.2px;
  overflow: hidden;
}
.block {
  text-align: center;
}
</style>
<style>
.content-wp > p {
  margin-top: 6px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #888;
  font-size: 14px;
}
.use .el-pagination button,
.use .el-pagination button span {
  min-width: 64px !important;
  height: 40px !important;
  line-height: 40px !important;
  background: #edeff4;
}
.use .el-pagination.is-background .el-pager li {
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: #edeff4;
}
</style>